<template>
  <v-card-text :class="mainClass">
    <v-row no-gutters align="center" :class="margins">
      <v-col
        :cols="cols[0]"
        justify="center"
        :align-self="alignLabel"
        align="start"
      >
        <!-- le label -->
        <slot name="label">
          <div class="capitalize font-weight-regular">
            {{ label }}
          </div>
        </slot>
      </v-col>

      <!-- la donnée associée -->
      <v-col :cols="cols[1]" align-self="center" align="start">
        <slot name="value">
          <div>{{ value }}</div>
        </slot>
      </v-col>
    </v-row>

    <v-divider v-if="divider"></v-divider>
  </v-card-text>
</template>

<script>
export default {
  props: {
    /**le label de la donnée */
    label: {
      type: String,
      default: "LABEL",
    },
    value: {
      default: "-",
    },
    /**Est-ce que le divider est affiché en dessous?*/
    divider: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: Array,
      default: function () {
        return [5, 7];
      },
    },
    margins: {
      type: String,
      default: "mb-5 mt-1",
    },
    /** Position haut - bas du label */
    alignLabel: {
      type: String,
      default: "center",
    },
    mainClass: {
      type: String,
      default: "my-1 py-1",
    },
  },
};
</script>

<style>
</style>