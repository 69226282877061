<template>
  <div>
    <slot name="header"></slot>

    <v-card flat outlined class="mx-auto">
      <slot name="title">
        <v-card-title class="capitalize font-weight-regular">{{ title }} </v-card-title>
      </slot>

      <slot name="items">
        <div>les items à afficher</div>
      </slot>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DetailViewBoxComponent",
  props: {
    /**le titre de la vue 'détail'. */
    title: {
      type: String,
    },
  },
};
</script>

<style>
</style>