<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Établissement.JURIS" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="8" lg="9" md="10" sm="11" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewBoxComponent title="Détail de la partie JURIS d'un établissement">
          <template v-slot:items>
            <InputFieldComponent label="nom digital" :value="digitalName" />
            <InputFieldComponent label="structure juridique" :value="legalStructureName" />
            <InputFieldComponent label="siret" :value="siret" />
            <InputFieldComponent label="Code APE (établissement secondaire)" :value="ape" />
            <InputFieldComponent label="Administrateur principal d'établissement" :value="establishmentMainManager" />
            <InputFieldComponent label="nom finess" :value="finessName" />
            <InputFieldComponent label="numero finess" :value="finessNumber" :divider="false" />
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { EstablishmentJurisService } from "@/service/etablishment/establishment_juris_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";


export default {
  name: "DetailsActivity",
  components: { Workflow, TitleAndReturnComponent, DetailViewBoxComponent, InputFieldComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** les services api. */
      serviceJuris: null,
      serviceEstablishment: null,
      serviceLegalStructure:null,

      /**l'identifiant du de l'antité à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /** le nom digital  */
      digitalName: null,
      /** Le nom de la structure juridique */
      legalStructureName: null,
      /** le nom finess */
      finessName: null,
      /** le numéro finess */
      finessNumber: null,
      /** le siret  */
      siret: null,
      /** le code ape */
      ape: null,
      /** le référent */
      establishmentMainManager: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        /** Récupération : 
         * - de la partie Juris
         * - de l'établissement
         * - de la structure juridique
        */
        let entity = await this.serviceJuris.getById(this.entityId);
        let establishment = await this.serviceEstablishment.getById(this.entityId);
        let legalStructure = await this.serviceLegalStructure.getById(establishment.legalStructure);

        this.digitalName = establishment.digitalName;
        this.legalStructureName = legalStructure.digitalName;
        this.finessName = entity.finessName;
        this.finessNumber = entity.finessNumber;
        this.siret = entity.siret;
        this.ape = entity.ape;
        this.establishmentMainManager = entity.establishmentMainManager;        
        
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {    
    //on instancie les services
    this.serviceJuris = new EstablishmentJurisService(this.$api.getEstablishmentJurisApi());
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceLegalStructure = new LegalStructureService(this.$api.getLegalStructuresApi());

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>