var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-text",
    { class: _vm.mainClass },
    [
      _c(
        "v-row",
        { class: _vm.margins, attrs: { "no-gutters": "", align: "center" } },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: _vm.cols[0],
                justify: "center",
                "align-self": _vm.alignLabel,
                align: "start",
              },
            },
            [
              _vm._t("label", function () {
                return [
                  _c("div", { staticClass: "capitalize font-weight-regular" }, [
                    _vm._v(" " + _vm._s(_vm.label) + " "),
                  ]),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: _vm.cols[1],
                "align-self": "center",
                align: "start",
              },
            },
            [
              _vm._t("value", function () {
                return [_c("div", [_vm._v(_vm._s(_vm.value))])]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.divider ? _c("v-divider") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }