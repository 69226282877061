var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("header"),
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { flat: "", outlined: "" } },
        [
          _vm._t("title", function () {
            return [
              _c(
                "v-card-title",
                { staticClass: "capitalize font-weight-regular" },
                [_vm._v(_vm._s(_vm.title) + " ")]
              ),
            ]
          }),
          _vm._t("items", function () {
            return [_c("div", [_vm._v("les items à afficher")])]
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }