var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: "Établissement.JURIS" },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: { xl: "8", lg: "9", md: "10", sm: "11", xs: "12" },
                    },
                    [
                      _c("v-progress-linear", {
                        attrs: { indeterminate: "", active: _vm.loading },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("DetailViewBoxComponent", {
                attrs: {
                  title: "Détail de la partie JURIS d'un établissement",
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function () {
                      return [
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "nom digital",
                            value: _vm.digitalName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "structure juridique",
                            value: _vm.legalStructureName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: { label: "siret", value: _vm.siret },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "Code APE (établissement secondaire)",
                            value: _vm.ape,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "Administrateur principal d'établissement",
                            value: _vm.establishmentMainManager,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: { label: "nom finess", value: _vm.finessName },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "numero finess",
                            value: _vm.finessNumber,
                            divider: false,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }